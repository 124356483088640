import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RegularText } from '@components/ui/typography/Typography'
import UnderlineLink from '@components/common/UnderlineLink'
const OtherFeaturesCard = (props) => {
  return (
    <Wrapper>
      <CardImage>
        <GatsbyImage
          image={props.item.node.headImage.childImageSharp.gatsbyImageData}
          alt={props.item.node.title}
          width={234}
          height={178}
          loading="lazy"
        />
      </CardImage>
      <IconTextWrapper>
        <img src={props.item.node.icon.publicURL} alt={props.item.node.slug} width={38} height={30} loading="lazy" />
        <span className={`activeIconText-${props.item.node.slug}`}>
          {props.intl.formatMessage({ id: props.item.node.cardName })}
        </span>
      </IconTextWrapper>
      <CardTitle>
        <p>{props.intl.formatMessage({ id: props.item.node.title })}</p>
      </CardTitle>
      <CardText>
        <RegularText>{props.intl.formatMessage({ id: props.item.node.text })}</RegularText>
      </CardText>
      <LearnMoreWrapper>
        <UnderlineLink
          to={props.item.node.goToPath}
          text={props.intl.formatMessage({ id: props.item.node.linkText })}
          color={color[props.item.node.color]}
        />
      </LearnMoreWrapper>
    </Wrapper>
  )
}

export default OtherFeaturesCard

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 220px;
  margin: 10px;
  color: ${color.font};
  //background: red;

  @media screen and (min-width: ${breakpoint.lg}) {
    max-width: 280px;
  }
`

const CardImage = styled.div`
  margin-bottom: 35px;
  //background: green;
`
const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  margin-bottom: 10px;
  //background: blue;

  img {
    height: 18px;
    margin-right: 2px;
  }

  .activeIconText-idea-plan {
    color: ${color.ideaPlan};
  }

  .activeIconText-validation {
    color: ${color.ideaValidation};
  }

  .activeIconText-whiteboard {
    color: ${color.whiteboard};
  }
`

const CardTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  //background: yellow;

  @media screen and (min-width: ${breakpoint.lg}) {
    font-size: 26px;
  }
`

const CardText = styled.div`
  display: flex;
  font-weight: 300;
  font-size: 14px;
  //background: pink;

  @media screen and (min-width: ${breakpoint.lg}) {
    font-size: 16px;
  }
`

const LearnMoreWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  margin-top: 10px;
  //background: orange;
`
