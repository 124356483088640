import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import FreeAiToolsFormWrapper from '@components/pages/free-ai-tools/FreeAiToolsFormWrapper'
import SloganGeneratorForm from '@components/pages/free-ai-tools/forms/SloganGeneratorForm'

const SloganGeneratorFormSection = ({ intl }) => {
  return (
    <FreeAiToolsFormWrapper title={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.form.title' })}>
      <SloganGeneratorForm intl={intl} />
    </FreeAiToolsFormWrapper>
  )
}

export default injectIntl(SloganGeneratorFormSection)
