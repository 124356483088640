import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RegularText } from '@components/ui/typography/Typography'
const BenefitsCard = (props) => {
  return (
    <Wrapper>
      <CardImage>
        <GatsbyImage
          image={props.item.node.headImage.childImageSharp.gatsbyImageData}
          alt={props.item.node.head}
          width={234}
          height={178}
          loading="lazy"
        />
      </CardImage>
      <CardTitle>
        <p>{props.intl.formatMessage({ id: props.item.node.head })}</p>
      </CardTitle>
      <CardText>
        <RegularText>{props.intl.formatMessage({ id: props.item.node.text })}</RegularText>
      </CardText>
    </Wrapper>
  )
}

export default BenefitsCard

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 220px;
  margin: 10px;
  color: ${color.font};

  @media screen and (min-width: ${breakpoint.lg}) {
    max-width: 275px;
  }
`

const CardImage = styled.div`
  margin-bottom: 25px;
`

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;

  @media screen and (min-width: ${breakpoint.lg}) {
    font-size: 26px;
  }
`

const CardText = styled.div`
  font-weight: 300;
  font-size: 14px;

  @media screen and (min-width: ${breakpoint.lg}) {
    font-size: 16px;
  }
`
