import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import GeneratorHeaderSection from '@sections/free-ai-tools-generators/GeneratorHeaderSection'
import nameIcon from '@assets/images/free-ai-tools/businessNameGenerator.svg'
import GeneratorTextSection from '@sections/free-ai-tools-generators/GeneratorTextSection'
import GeneratorCTASection from '@sections/free-ai-tools-generators/GeneratorCTASection'
import GeneratorFAQSection from '@sections/free-ai-tools-generators/GeneratorFAQSection'
import BusinessNameGeneratorFormSection from '@sections/free-ai-tools-generators/business-name-generator/BusinessNameGeneratorFormSection'

const BusinessNameGenerator = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allBusinessNameGeneratorFaqJson {
        edges {
          node {
            key
            head
            body
          }
        }
      }
    }
  `)
  return (
    <Layout pageName="business-name-generator">
      <GeneratorHeaderSection
        titleIcon={nameIcon}
        title={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.subtitle' })}
      />
      <BusinessNameGeneratorFormSection />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.section01.title' })}
        description={[
          intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.section01.description01' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.section01.description02' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.section01.description03' }),
        ]}
      />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.section02.title' })}
        description={[
          intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.section02.description01' }),
        ]}
        list={[
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item01.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item01.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item02.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item02.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item03.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item03.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item04.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.business-name-generator.section02.list.item04.regular-text',
            }),
          },
        ]}
      />
      <GeneratorFAQSection faqData={data.allBusinessNameGeneratorFaqJson} />
      <GeneratorCTASection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.sectionCTA.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.sectionCTA.subtitle' })}
        buttonText={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.sectionCTA.buttonText' })}
      />
    </Layout>
  )
}

export default injectIntl(BusinessNameGenerator)
