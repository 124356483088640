import React from 'react'
import styled from 'styled-components'
import { color, transition } from '@configs/utilities'
import arrow from '@assets/images/common/arrowRight.svg'
import scrollTo from 'gatsby-plugin-smoothscroll'

const ScrollLinkComponent = ({ text, to, className, color, hideArrow = false }) => {
  return (
    <Wrapper color={color}>
      <button onClick={() => scrollTo(to)} className={className}>
        {text}
      </button>
      {!hideArrow && <img src={arrow} alt="arrow-right" />}
    </Wrapper>
  )
}

export default ScrollLinkComponent

const Wrapper = styled.div`
  display: flex;
  transition: ${transition.default};
  column-gap: 5px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      img {
        margin-left: 5px;
        transition: ${transition.default};
      }
    }
  }

  button {
    cursor: pointer;
    background: transparent;
    color: ${color.font};
    font-size: 16px;
    font-weight: 600;
    ${({ color }) =>
      color &&
      `
    border-bottom: 2px solid ${color};
  `}
  }

  button,
  img {
    padding: 0 0 2px;
  }
`
