import React, { useEffect, useState } from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import { color } from '@configs/utilities'
import { generateAppLink, getLanguageNameByLocale } from '@configs/helpers'
import ReadySection from '@sections/common/ReadySection'
import VideoReviewSection from '@sections/home/VideoReviewSection'
import TestimonialsSection from '@sections/common/TestimonialsSection'
import AdvertisementSection from '@sections/common/AdvertisementSection'
import GeneratorOutputSectionTextImageSection from '@sections/free-ai-tools-generators-output/GeneratorOutputSectionTextImageSection'
import GeneratorOutputFrequentlyAskedSection from '@sections/free-ai-tools-generators-output/GeneratorOutputFrequentlyAskedSection'
import GeneratorOutputSection from '@sections/free-ai-tools-generators-output/GeneratorOutputSection'
import { getSearchParams } from 'gatsby-query-params'
import _isEmpty from 'lodash/isEmpty'
import GeneratorOutputBannerSection from '@sections/free-ai-tools-generators-output/GeneratorOutputBannerSection'
import axios from 'axios'
import businessIdeaGeneratorFAQSectionIcon from '@assets/images/free-ai-tools/businessIdeaGenerator.svg'

const Output = ({ intl }) => {
  const BASE_URL = process.env.APP_BASE_URL

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const searchParams = getSearchParams()
  useEffect(() => {
    const languageName = getLanguageNameByLocale(intl.locale)

    const payload = { ...searchParams, languageName }

    if (!_isEmpty(searchParams)) {
      axios
        .post(`${BASE_URL}api/v1/free-ai-tools/business-idea-generator`, payload, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          setData(response.data.payload.data)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [searchParams])

  return (
    <Layout>
      <GeneratorOutputSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.business-idea-generator.output.header.title' })}
        icon={businessIdeaGeneratorFAQSectionIcon}
        startAgainLink="/free-ai-tools/business-idea-generator/"
        data={data}
        loading={loading}
      />
      <GeneratorOutputBannerSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.business-idea-generator.output.banner.title' })}
        description={intl.formatMessage({
          id: 'free-ai-tools-pages.business-idea-generator.output.banner.description',
        })}
        buttonText={intl.formatMessage({
          id: 'free-ai-tools-pages.business-idea-generator.output.banner.buttonText',
        })}
      />
      <GeneratorOutputSectionTextImageSection />
      <AdvertisementSection
        componentBackground="radial-gradient(50% 50% at 50% 50%, #0091FF 0%, #0E64E6 100%)"
        subTitleColor={color.white}
        titleText={intl.formatMessage({ id: 'home.businessPlanning.title' })}
        subtitleText={intl.formatMessage({ id: 'home.businessPlanning.subtitle' })}
        // to="/pricing"
        external
        href={generateAppLink('signup', intl.locale)}
        buttonBackground={color.yellow}
        buttonColor={color.white}
        buttonText={intl.formatMessage({ id: 'home.hero.startFree' })}
      />
      <GeneratorOutputFrequentlyAskedSection />
      <TestimonialsSection />
      <VideoReviewSection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: 'home.videoReviewSection.title' })}
        description={intl.formatMessage({ id: 'home.videoReviewSection.description' })}
      />
      <ReadySection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: 'home.readyToStartYourAdventure.title' })}
        description={intl.formatMessage({ id: 'home.readyToStartYourAdventure.description' })}
        buttonText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.buttonText' })}
        // to="/pricing"
        external
        href={generateAppLink('signup', intl.locale)}
        explain
        explainText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.explainText' })}
      />
    </Layout>
  )
}

export default injectIntl(Output)
