import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { breakpoint, color, transition } from '@configs/utilities'
import arrow from '@assets/images/free-ai-tools/arrow.svg'
import SectionWrapperFlexible from '@components/ui/wrappers/SectionWrapperFlexible'
import TagSoon from '@components/common/TagSoon'
import { useWindowSize } from '@configs/getGlobalWindowSize'

const FreeAiToolsCardsSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allFreeAiToolsHomeCardsJson {
        edges {
          node {
            head
            text
            goTo
            soonTag
            headImage {
              publicURL
            }
          }
        }
      }
    }
  `)

  function cardBody(item) {
    return (
      <>
        <ImageWrapper>
          <img src={item.node.headImage.publicURL} className="headImage" alt="card icon" loading="lazy" />
        </ImageWrapper>
        <TextWrapper>
          <HeadWrapper>
            <p className="head">{intl.formatMessage({ id: item.node.head })}</p>
            {item.node.soonTag && <TagSoon />}
          </HeadWrapper>
          <p className="text">{intl.formatMessage({ id: item.node.text })}</p>
        </TextWrapper>
      </>
    )
  }

  const currentWidth = useWindowSize().width

  return (
    <SectionWrapperFlexible
      sectionBackground={color.white}
      sectionPaddingSm="0"
      sectionPaddingMd="0"
      sectionPaddingXl="0"
    >
      {currentWidth > 991 ? (
        <Handwriting>
          <img src={arrow} alt="arrow" />
          <p>{intl.formatMessage({ id: 'free-ai-tools.handwriting' })}</p>
        </Handwriting>
      ) : null}
      <Wrapper>
        {data.allFreeAiToolsHomeCardsJson.edges.map((item, index) => {
          return item.node.goTo ? (
            <Card key={index} to={item.node.goTo}>
              {cardBody(item)}
            </Card>
          ) : (
            <CardDiv key={index}>{cardBody(item)}</CardDiv>
          )
        })}
      </Wrapper>
    </SectionWrapperFlexible>
  )
}
export default injectIntl(FreeAiToolsCardsSection)

const Handwriting = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Caveat Brush', cursive;
  font-size: 30px;
  text-align: center;
  color: ${color.blue};
  margin-bottom: 20px;

  img {
    margin: 30px 10px 0 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 25px;
`

const Card = styled(Link)`
  width: 100%;
  max-width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-radius: 8.8px;
  border: 1px solid ${color.grayDark};
  background: ${color.white};
  box-shadow: 2px 2px 4px 0 rgba(8, 30, 74, 0.13);
  text-align: center;
  cursor: pointer;
  transition: ${transition.default};

  &:hover {
    background: ${color.backgroundHover2};
    border: 1px solid ${color.whiteboard};

    .head {
      color: ${color.blue};
    }
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    max-width: 48%;
    flex-direction: row;
    column-gap: 20px;
    text-align: left;
    padding: 30px;
  }
`

const CardDiv = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-radius: 8.8px;
  border: 1px solid ${color.grayDark};
  background: ${color.white};
  box-shadow: 2px 2px 4px 0 rgba(8, 30, 74, 0.13);
  text-align: center;
  //cursor: pointer;
  transition: ${transition.default};

  &:hover {
    background: ${color.backgroundHover2};
    border: 1px solid ${color.whiteboard};

    .head {
      color: ${color.blue};
    }
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    max-width: 48%;
    flex-direction: row;
    column-gap: 20px;
    text-align: left;
    padding: 30px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    min-width: 80px;
    @media screen and (min-width: ${breakpoint.lg}) {
      width: 100%;
    }
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    max-width: 80px;
  }
`

const TextWrapper = styled.div`
  color: ${color.font};
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;

  .head {
    font-size: 26px;
    font-weight: 600;
  }

  .text {
    font-size: 18px;
    font-weight: 300;
  }
`

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    column-gap: 10px;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    justify-content: flex-start;
  }
`
