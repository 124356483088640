import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import FreeAiToolsFormWrapper from '@components/pages/free-ai-tools/FreeAiToolsFormWrapper'
import BusinessNameGeneratorForm from '@components/pages/free-ai-tools/forms/BusinessNameGeneratorForm'

const BusinessNameGeneratorFormSection = ({ intl }) => {
  return (
    <FreeAiToolsFormWrapper
      title={intl.formatMessage({ id: 'free-ai-tools-pages.business-name-generator.form.title' })}
    >
      <BusinessNameGeneratorForm intl={intl} />
    </FreeAiToolsFormWrapper>
  )
}

export default injectIntl(BusinessNameGeneratorFormSection)
