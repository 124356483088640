import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'

const AdvertisementSection = ({ intl, ...props }) => {
  return (
    <SectionWrapper
      sectionBackground={props.sectionBackground}
      showWaves={props.showWaves}
      wavesPosition={props.wavesPosition}
    >
      <Wrapper componentBackground={props.componentBackground}>
        <TextWrapper subTitleColor={props.subTitleColor}>
          <p className="title">{props.titleText}</p>
          <p className="subTitle">{props.subtitleText}</p>
        </TextWrapper>

        <ButtonLink
          external={props.external}
          targetBlank={props.targetBlank}
          href={props.href}
          to={props.to}
          buttonText={props.buttonText}
          buttonBackground={props.buttonBackground}
          buttonColor={props.buttonColor}
          explain={props.explain}
          explainText={props.explainText}
        />
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(AdvertisementSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: ${(props) => props.componentBackground || color.blueDark};
  border-radius: 12px;
  padding: 30px 40px;

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 45px 35px 42px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  text-align: center;
  row-gap: 20px;
  margin-bottom: 40px;

  .title {
    color: ${color.white};
    font-size: 20px;

    @media screen and (min-width: ${breakpoint.sm}) {
      font-size: 26px;
    }

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 32px;
    }
  }

  .subTitle {
    //color: ${color.advertisementBlue};
    color: ${(props) => props.subTitleColor || color.advertisementBlue};
    font-size: 15px;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
`
