import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import FreeAiToolsHeaderSection from '@sections/free-ai-tools/FreeAiToolsHeaderSection'
import FreeAiToolsQuestionsSection from '@sections/free-ai-tools/FreeAiToolsQuestionsSection'
import FreeAiToolsCardsSection from '@sections/free-ai-tools/FreeAiToolsCardsSection'
import ReadySection from '@sections/common/ReadySection'
import RatingSection from '@sections/home/RatingSection'
import { color } from '@configs/utilities'
import { generateAppLink } from '@configs/helpers'

const FreeAiTools = ({ intl }) => {
  return (
    <Layout pageName="free-ai-tools">
      <FreeAiToolsHeaderSection />
      <FreeAiToolsCardsSection />
      <FreeAiToolsQuestionsSection />
      <ReadySection
        sectionBackground={color.white}
        title={intl.formatMessage({ id: 'home.readyToStartYourAdventure.title' })}
        description={intl.formatMessage({ id: 'home.readyToStartYourAdventure.description' })}
        buttonText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.buttonText' })}
        external
        href={generateAppLink('signup', intl.locale)}
        explain
        explainText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.explainText' })}
      />
      <RatingSection />
    </Layout>
  )
}

export default injectIntl(FreeAiTools)
