import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color } from '@configs/utilities'

const TagSoon = ({ intl }) => {
  return (
    <TagWrapper>
      <span>{intl.formatMessage({ id: 'miscellaneous.soon' })}</span>
    </TagWrapper>
  )
}

export default injectIntl(TagSoon)

const TagWrapper = styled.div`
  width: fit-content;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background: ${color.yellow};
  color: ${color.white};
  border-radius: 8px;
  padding: 5px 5px;
  line-height: normal;
`
