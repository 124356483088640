import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import GeneratorHeaderSection from '@sections/free-ai-tools-generators/GeneratorHeaderSection'
import sloganIcon from '@assets/images/free-ai-tools/sloganGenerator.svg'
import GeneratorTextSection from '@sections/free-ai-tools-generators/GeneratorTextSection'
import GeneratorCTASection from '@sections/free-ai-tools-generators/GeneratorCTASection'
import GeneratorFAQSection from '@sections/free-ai-tools-generators/GeneratorFAQSection'
import SloganGeneratorFormSection from '@sections/free-ai-tools-generators/slogan-generator/SloganGeneratorFormSection'

const SloganGenerator = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allSloganGeneratorFaqJson {
        edges {
          node {
            key
            head
            body
          }
        }
      }
    }
  `)
  return (
    <Layout pageName="slogan-generator">
      <GeneratorHeaderSection
        titleIcon={sloganIcon}
        title={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.subtitle' })}
      />
      <SloganGeneratorFormSection />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.section01.title' })}
        description={[
          intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.section01.description01' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.section01.description02' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.section01.description03' }),
        ]}
      />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.section02.title' })}
        description={[intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.section02.description01' })]}
        list={[
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item01.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item01.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item02.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item02.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item03.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item03.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item04.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.slogan-generator.section02.list.item04.regular-text',
            }),
          },
        ]}
      />
      <GeneratorFAQSection faqData={data.allSloganGeneratorFaqJson} />
      <GeneratorCTASection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.sectionCTA.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.sectionCTA.subtitle' })}
        buttonText={intl.formatMessage({ id: 'free-ai-tools-pages.slogan-generator.sectionCTA.buttonText' })}
      />
    </Layout>
  )
}

export default injectIntl(SloganGenerator)
