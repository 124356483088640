import './src/styles/styles.scss'
import './fonts.scss'
import '@mantine/core/styles.css'

import React from 'react'
import { MantineProvider } from '@mantine/core'
import { theme } from './src/theme'

export const wrapPageElement = ({ element }) => {
  return <MantineProvider theme={theme}>{element}</MantineProvider>
}

export const onRouteUpdate = () => {
  window.scrollTo(0, 0)
}
