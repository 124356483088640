import React from 'react'
import Collapsible from 'react-collapsible'
import styled from 'styled-components'
import angle from '@assets/images/pricing/frequentlyAsked/arrow.svg'
import { color, breakpoint, transition } from '@configs/utilities'

const AccordionFrequentlyAsked = (props) => {
  return (
    <AccordionWrapper>
      <Collapsible
        containerElementProps={{ id: `container-element-${props.id}-id-footer` }}
        contentElementId={`content-element-${props.id}-id-footer`}
        triggerElementProps={{ id: `trigger-element-${props.id}-id-footer` }}
        transitionTime={175}
        trigger={
          <Trigger>
            <span>{props.head}</span> <img src={angle} alt="angle" width={14} />
          </Trigger>
        }
      >
        <AccordionText>
          <p>{props.text}</p>
          <AccordionList>{props.list}</AccordionList>
        </AccordionText>
      </Collapsible>
    </AccordionWrapper>
  )
}

export default AccordionFrequentlyAsked

const AccordionWrapper = styled.div`
  width: 100%;
  margin: 10px auto;
  padding: 0 15px;
  background: ${color.white};
  border-radius: 8px;
  border: 1px solid rgba(187, 201, 228, 0.33);
  color: ${color.font};

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 0 20px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    padding: 0 30px;
  }

  .is-open {
    img {
      transform: rotate(180deg);
    }
  }
`

const Trigger = styled.div`
  width: 100%;
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    width: 85%;
    font-weight: 500;
    font-size: 16px;

    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 18px;
    }
  }

  img {
    transition: ${transition.default};
    width: 12px;

    @media screen and (min-width: ${breakpoint.xl}) {
      width: 14px;
    }
  }
`

const AccordionText = styled.div`
  font-weight: 300;
  padding-bottom: 15px;
  font-size: 16px;

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 18px;
  }
`
const AccordionList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0 0 20px;

  li {
    width: 45%;
  }
`
