import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import FreeAiToolsFormWrapper from '@components/pages/free-ai-tools/FreeAiToolsFormWrapper'
import BusinessIdeaGeneratorForm from '@components/pages/free-ai-tools/forms/BusinessIdeaGeneratorForm'

const BusinessIdeaGeneratorFormSection = ({ intl }) => {
  return (
    <FreeAiToolsFormWrapper
      title={intl.formatMessage({ id: 'free-ai-tools-pages.business-idea-generator.form.title' })}
    >
      <BusinessIdeaGeneratorForm intl={intl} />
    </FreeAiToolsFormWrapper>
  )
}

export default injectIntl(BusinessIdeaGeneratorFormSection)
