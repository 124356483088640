import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import PageHeader from '@components/ui/typography/PageHeader'
import SectionWrapperFlexible from '@components/ui/wrappers/SectionWrapperFlexible'

const FreeAiToolsHeaderSection = ({ intl }) => {
  return (
    <SectionWrapperFlexible
      sectionPaddingSm="30px 0 30px 0"
      sectionPaddingMd="40px 0 0 0"
      sectionPaddingXl="60px 0 0 0"
    >
      <Wrapper>
        <PageHeader
          title={intl.formatMessage({ id: 'free-ai-tools.hero.title' })}
          description={intl.formatMessage({ id: 'free-ai-tools.hero.text' })}
        />
      </Wrapper>
    </SectionWrapperFlexible>
  )
}

export default injectIntl(FreeAiToolsHeaderSection)

const Wrapper = styled.div`
  max-width: 840px;
  margin: 0 auto;
`
