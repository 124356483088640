import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { Link } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { breakpoint, color, transition } from '@configs/utilities'
import BenefitsCard from '@components/pages/home/benefitsCard/BenefitsCard'

const AffiliateProgramBenefitSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allBenefitPartnershipCardsJson {
        edges {
          node {
            headImage {
              childImageSharp {
                gatsbyImageData(width: 116, placeholder: BLURRED, formats: [AUTO, WEBP], breakpoints: [320, 768, 1380])
              }
            }
            head
            text
          }
        }
      }
    }
  `)

  return (
    <SectionWrapper>
      <SectionHeader title={intl.formatMessage({ id: 'affiliateProgram.benefitPartnership.title' })} />
      <CardsWrapper>
        {data.allBenefitPartnershipCardsJson.edges.map((item, index) => {
          return <BenefitsCard key={index} item={item} intl={intl} />
        })}
      </CardsWrapper>
      <TextWrapper>
        <p>{intl.formatMessage({ id: 'affiliateProgram.benefitPartnership.cookieText' })}</p>
        <p className="beforeSigningUp">
          <span>{intl.formatMessage({ id: 'affiliateProgram.benefitPartnership.beforeSigningUp' })}</span>
          <Link to="/affiliate-program/terms/" className="linkTerms">
            {intl.formatMessage({ id: 'affiliateProgram.benefitPartnership.termsOfService' })}
          </Link>
          <span>{intl.formatMessage({ id: 'affiliateProgram.benefitPartnership.carefully' })}</span>
        </p>
      </TextWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(AffiliateProgramBenefitSection)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 0;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    margin-top: 25px;
    justify-content: space-between;
  }
`
const TextWrapper = styled.div`
  max-width: 887px;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;

  @media screen and (min-width: ${breakpoint.md}) {
    padding-top: 60px;
  }

  p {
    font-size: 16px;
    color: ${color.font};

    @media screen and (min-width: ${breakpoint.lg}) {
      font-size: 18px;
    }
  }

  .beforeSigningUp {
    margin-top: 20px;

    .linkTerms {
      color: ${color.blue};
      transition: ${transition.default};
      cursor: pointer;
      padding: 5px;

      :hover {
        opacity: 0.7;
      }
    }
  }
`
