import React from 'react'
import Layout from '@components/layouts/default/Layout'
import ServicesWrapper from '@components/ui/wrappers/ServicesWrapper'

const Terms = () => {
  return (
    <Layout pageName="affiliate-program-terms">
      <ServicesWrapper>
        <h1>Affiliate Partners - Terms of Service</h1>
        <h2>1. Eligibility</h2>
        <ul className="paddingVertical">
          To join the IdeaBuddy Affiliate Program, you must:
          <li className="marginTop">Be at least 18 years old.</li>
          <li>Operate a legitimate business or website that aligns with the values and goals of IdeaBuddy.</li>
          <li>Not engage in activities or operate in jurisdictions prohibited by law or these terms.</li>
          <li>
            Receive approval after submitting your application to IdeaBuddy. Approval is at our sole discretion and may
            be revoked at any time if eligibility criteria are not met.
          </li>
        </ul>
        <h2>2. Commission Policies and Flexibility</h2>
        <ul className="paddingVertical">
          <li className="marginTop">
            IdeaBuddy reserves the right to modify commission structures, rates, and payout thresholds at any time with
            prior notice.
          </li>
          <li>
            Changes will be communicated through email or the affiliate dashboard, and continued participation in the
            program constitutes acceptance of such changes.
          </li>
        </ul>
        <h2>3. Commission Payments</h2>
        <ul className="paddingVertical">
          <li className="marginTop">
            Affiliates are eligible for commission payouts once they reach a minimum threshold of $50.
          </li>
          <li>
            Payments will be processed monthly, typically within 30 days following the end of the month in which
            commissions are earned.
          </li>
          <li>Commissions are paid via PayPal or another mutually agreed-upon method.</li>
        </ul>
        <h2>4. Brand Usage Guidelines</h2>
        <ul className="paddingVertical">
          <li className="marginTop">
            Affiliates may use the IdeaBuddy name, logo, and branding materials solely for promoting IdeaBuddy products
            and services, provided they:
          </li>
          <li>Use the materials as provided by IdeaBuddy without alteration.</li>
          <li>Do not imply endorsement or partnership outside the scope of the affiliate relationship.</li>
          <li>
            Avoid using the brand name in paid advertisements, including PPC campaigns, or bidding on branded keywords.
          </li>
          <li>Obtain prior approval for any custom marketing materials featuring IdeaBuddy branding.</li>
        </ul>
        <h2>5. Prohibited Activities</h2>
        <ul className="paddingVertical">
          The following activities will result in immediate removal from the IdeaBuddy Affiliate Program and forfeiture
          of unpaid commissions:
          <li className="marginTop">
            Fraudulent Practices: Using tactics such as self-referrals, cookie stuffing, or employing bots to generate
            fake referrals.
          </li>
          <li>
            Brand Misuse: Bidding on IdeaBuddy branded keywords, using tracking codes such as "search" to simulate
            organic traffic, or running deceptive ads.
          </li>
          <li>
            Unauthorized Advertising: Creating or promoting paid advertisements using IdeaBuddy branding without written
            consent.
          </li>
          <li>Misrepresentation: Making false claims about IdeaBuddy’s products, services, or affiliate program.</li>
          <li>Illegal Activities: Any actions that violate applicable laws or regulations.</li>
        </ul>
        <h2>6. Program Termination</h2>
        <p className="marginBottom">
          IdeaBuddy reserves the right to terminate the affiliate relationship at any time for breach of these terms or
          for any reason deemed necessary to protect the integrity of the program. Affiliates will be notified of
          termination via email, and any unpaid commissions may be withheld if fraud or prohibited activities are
          detected.
        </p>
        <h2>7. Limitation of Liability</h2>
        <p className="marginBottom">
          IdeaBuddy is not liable for any indirect, incidental, or consequential damages resulting from participation in
          the affiliate program. Affiliates agree to indemnify and hold IdeaBuddy harmless from any claims arising out
          of their marketing activities.
        </p>
        <h2>8. Governing Law</h2>
        <p className="marginBottom">
          These terms are governed by and construed in accordance with the laws of Serbia, and any disputes will be
          resolved in the courts of Serbia.
        </p>
        <h2>9. Acceptance of Terms</h2>
        <p className="marginBottom">
          By joining and participating in the IdeaBuddy Affiliate Program, you agree to abide by these Terms of Service.
          Failure to comply may result in removal from the program and forfeiture of any pending commissions.
        </p>
      </ServicesWrapper>
    </Layout>
  )
}

export default Terms
